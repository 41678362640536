import React from 'react'
import cx from 'classnames'

import getBackgroundColor from './util/getBackgroundColor'


export type AvatarProps = {
  className?: string
  firstName: string
  lastName: string
  size: 40 | 32
  isActive?: boolean
  'data-testid'?: string
}

const sizeToClassName = {
  32: 'w-s32 h-s32 p-[2px]',
  40: 'w-s40 h-s40 outline-offset-2',
}

const getLetter = (str: string) => str ? str[0] : ''

const Avatar: React.FC<AvatarProps> = (props) => {
  const { className, firstName, lastName, size, isActive, 'data-testid': dataTestId } = props

  const isDefault = firstName === 'Scentbird' && lastName === 'User'
  const initials = isDefault ? 'SB' : `${getLetter(firstName)}${getLetter(lastName)}`

  const backgroundColor = getBackgroundColor(initials)

  const rootClassName = cx(
    className,
    'flex flex-none select-none items-stretch overflow-hidden rounded-full',
    sizeToClassName[size],
    isActive && 'outline outline-1 outline-[var(--av-color)]'
  )

  const innerClassName = cx('flex w-full items-center justify-center rounded-full bg-[var(--av-color)] text-white', size === 40 ? 'text-h8' : 'text-h9')

  return (
    // @ts-expect-error
    <div className={rootClassName} style={{ '--av-color': backgroundColor }} data-testid={dataTestId}>
      <div className={innerClassName}>
        {initials}
      </div>
    </div>
  )
}


export default React.memo(Avatar)
