import React, { useRef, useMemo, useState, useCallback, useEffect } from 'react'
import cx from 'classnames'
import { useDevice } from 'device'
import logger from 'logger'

import Preview from '../Preview/Preview'


declare global {
  interface Window {
    onYouTubeIframeAPIReady: () => void
  }
}

const youtubeIframeAPIState = {
  isScriptInserted: false,
  isReady: false,
  onReadyWaitList: [],
}

const initApi = (callback) => {
  if (youtubeIframeAPIState.isReady) {
    callback()
    return
  }

  youtubeIframeAPIState.onReadyWaitList.push(callback)

  if (youtubeIframeAPIState.isScriptInserted) {
    return
  }

  const tag = document.createElement('script')
  tag.src = 'https://www.youtube.com/iframe_api'

  const firstScriptTag = document.getElementsByTagName('script')[0]
  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

  youtubeIframeAPIState.isScriptInserted = true

  window.onYouTubeIframeAPIReady = () => {
    youtubeIframeAPIState.isReady = true
    youtubeIframeAPIState.onReadyWaitList.forEach((callback) => callback())
  }
}

export type YoutubePlayerProps = {
  className?: string
  url: string
  preview?: string
  autoplay?: boolean
  previewStyle?: 'default' | 'drift' | 'gold30' | 'withoutIcon'
  previewIconSize?: 18 | 60 | 72;
  onPlay?: () => void
}

const YoutubePlayer: React.FunctionComponent<YoutubePlayerProps> = (props) => {
  const { className, url, preview, autoplay, previewStyle = 'default', previewIconSize, onPlay } = props

  const { isMobile } = useDevice()
  const ref = useRef<HTMLIFrameElement>()
  const [ isPlayOn, setIsPlayOn ] = useState(autoplay && !preview)

  const withPreview = Boolean(preview && preview !== url)

  const src = useMemo(() => {
    if (autoplay || withPreview) {
      const hasQuery = /\?[^?]+$/.test(url)

      return `${url}${hasQuery ? '&' : '?'}autoplay=1&enablejsapi=1`
    }

    return url
  }, [ url, autoplay, withPreview ])

  const [ isVideoVisible, setVideoVisibility ] = useState(!withPreview)

  const handlePlaceholderClick = useCallback(() => {
    setVideoVisibility(true)

    if (typeof onPlay === 'function') {
      onPlay()
    }

    setIsPlayOn(true)
  }, [ onPlay ])

  useEffect(() => {
    if (isMobile && isPlayOn) {
      // it's a big fallback for mobile devices
      // autoplay isn't working in mobile browsers, so we should start video manually by API
      // to do so, we should load iframe api
      initApi(() => {
        // we need setTimeout, because iframe should be rendered after state update
        setTimeout(() => {
          try {
            // @ts-expect-error
            const player = new window.YT.Player(ref.current, {
              events: {
                'onReady': () => {
                  player.playVideo()
                },
              },
            })
          }
          catch (error) {
            logger.error(error)
          }
        }, 50)
      })
    }
  }, [ isMobile, isPlayOn ])

  return (
    <>
      {
        Boolean(withPreview) && (
          <Preview
            bgImage={preview}
            previewStyle={previewStyle}
            previewIconSize={previewIconSize}
            onClick={handlePlaceholderClick}
          />
        )
      }
      {
        isVideoVisible && (
          <iframe
            ref={ref}
            className={cx(className, 'absolute left-0 top-0 size-full')}
            src={src}
            title="youtube video"
            allow="autoplay"
            frameBorder="0"
            allowFullScreen
          />
        )
      }
    </>
  )
}


export default YoutubePlayer
