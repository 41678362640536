import React from 'react'

import { buttonMessages } from 'components/inputs'
import { Text, PlayIcon } from 'components/dataDisplay'


export type VideoPreviewIconProps = {
  style: 'default' | 'drift' | 'gold30' | 'withoutIcon'
  size?: 18 | 60 | 72
}

const VideoPreviewIcon: React.FC<VideoPreviewIconProps> = ({ style, size }) => {
  if (style === 'withoutIcon') {
    return null
  }

  if (style === 'drift') {
    return (
      <div className="absolute bottom-16 right-16 flex items-center rounded-full bg-white py-8 pl-16 pr-12">
        <Text className="mr-6" style="h8" message={buttonMessages.playVideo} />
        <PlayIcon size={18} style="drift" />
      </div>
    )
  }

  return (
    <div className="absolute left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2">
      <PlayIcon
        size={size ?? (style === 'gold30' ? 60 : 72)}
        style={style === 'gold30' ? 'gold-30' : 'default'}
      />
    </div>
  )
}

export default VideoPreviewIcon
