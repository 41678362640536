import React, { forwardRef } from 'react'
import cx from 'classnames'

import { Href } from 'components/navigation'
import { Text, MediaHeadlineBlock } from 'components/dataDisplay'
import type { MediaHeadlineBlockProps } from 'components/dataDisplay'
import { ActionButton } from 'components/inputs'
import type { ActionButtonProps } from 'components/inputs'

import s from './PromoCardContainer.module.css'


export type PromoCardContainerProps = Omit<MediaHeadlineBlockProps, 'titleStyle'> & {
  children: React.ReactElement
  withHighlight?: boolean // TODO seems we don't need "highlight", it should be included in an image - added on 24.08.2021 by sonatskiy
  titleStyle?: MediaHeadlineBlockProps['titleStyle']
  action?: Pick<ActionButtonProps, 'title' | 'to' | 'toTab' | 'href' | 'onClick'>
  dataTestId?: string
}

const PromoCardContainer = forwardRef<any, PromoCardContainerProps>((props, ref) => {
  const { children, className, label, title, titleStyle = 'h3', titleTag, text, action, indent = 16, withHighlight, dataTestId } = props

  const mediaContent = (
    <div className="relative overflow-hidden">
      {children}
      {
        withHighlight && (
          <Text
            className={cx(s.titlePlacement, indent && `ml-${indent}`)}
            message={title}
            style={titleStyle}
            html
          />
        )
      }
    </div>
  )

  if (action) {
    const { to, toTab, href, onClick } = action

    return (
      <Href
        className={cx(className, 'pb-8')}
        to={to}
        toTab={toTab}
        href={href}
        onClick={onClick}
        ref={ref}
        data-testid={dataTestId}
      >
        {mediaContent}
        <MediaHeadlineBlock
          title={title}
          titleStyle={titleStyle}
          titleTag={titleTag}
          text={text}
          label={label}
          indent={indent}
        >
          <ActionButton
            className="mt-16"
            title={action.title}
            style="gold-50"
            tag="div"
          />
        </MediaHeadlineBlock>
      </Href>
    )
  }

  return (
    <div className={cx(className, 'pb-8')} ref={ref} data-testid={dataTestId}>
      {mediaContent}
      <MediaHeadlineBlock
        title={title}
        titleStyle={titleStyle}
        titleTag={titleTag}
        text={text}
        label={label}
        indent={indent}
      />
    </div>
  )
})


export default React.memo(PromoCardContainer)
