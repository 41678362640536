import React from 'react'
import cx from 'classnames'

import { Text, type TextStyle } from 'components/dataDisplay'


export type ProgressProps = {
  className?: string
  message?: Intl.Message | string
  progress: number
  textStyle?: TextStyle
  'data-testid'?: string
}

const Progress: React.CFC<ProgressProps> = (props) => {
  const { children, className, message, progress, textStyle = 'p4', 'data-testid': dataTestId } = props

  return (
    <div className={cx('-mt-16', className)} data-testid={dataTestId}>
      <div className="flex items-center justify-between">
        {
          Boolean(message) && (
            <Text
              message={message}
              style={textStyle}
              html
            />
          )
        }
      </div>
      {children}
      <div className="bg-gray-20 mt-8 h-8">
        <div className="bg-gold-30 ease-ease-in-out h-full transition-all duration-500" style={{ width: `${progress}%` }} />
      </div>
    </div>
  )
}

export default Progress
