import React, { forwardRef } from 'react'
import { useDevice } from 'device'

import { twcx } from 'helpers'

import { Text, type TextStyle } from 'components/dataDisplay'


type ColorVariant = 'green' | 'gold'

// Extend palette with new colors when needed
const colorStyles: Record<ColorVariant, string> = {
  gold: 'bg-gold-70',
  green: 'bg-green',
}

type ColorLabelProps = {
  className?: string;
  color: ColorVariant;
  style?: TextStyle;
  text: string | Intl.Message;
  'data-testid'?: string;
  isCompactLayout?: boolean
}

const ColorLabel = forwardRef<HTMLDivElement, ColorLabelProps>((props, ref) => {
  const {
    className,
    color,
    style,
    text,
    isCompactLayout,
    'data-testid': dataTestId,
  } = props

  const { isMobile } = useDevice()

  const mobileVersion = isCompactLayout || isMobile

  const rootClassName = twcx(
    'rounded-4 inline-block whitespace-nowrap px-4 py-2',
    colorStyles[color],
    className
  )

  return (
    <div className={rootClassName} data-testid={dataTestId}>
      <Text ref={ref} message={text} style={style || (mobileVersion ? 'eye3' : 'h8')} color="white" />
    </div>
  )
})

export default ColorLabel
