import React, { useCallback, useState } from 'react'
import cx from 'classnames'
import { useIntl } from 'intl'
import { array } from 'helpers'
import { useUniqueId } from 'hooks'
import { getGlobalHtmlAttrs } from 'helpers/getters'

import { Text } from 'components/dataDisplay'

import useRatingKeyboard from './util/useRatingKeyboard'

import messages from './messages'
import s from './Rating.module.css'


export const sizes = [ 40, 20, 14, 9 ] as const

export type RatingProps = {
  className?: string
  hintClassName?: string
  value?: number
  size: typeof sizes[number]
  withHint?: boolean
  hintPosition?: 'top' | 'bottom'
  fillStyle?: 'bg-gold-70' | 'bg-gold-50' | 'bg-gold-30' | `bg-${string}`
  onChange?: (value: number) => void
  onKeyDown?: (event: React.KeyboardEvent) => void
}

const ReadonlyRating: React.FunctionComponent<RatingProps> = (props) => {
  const { className, hintClassName, value, size, fillStyle = 'bg-gold-50', ...otherProps } = props

  const intl = useIntl()
  const htmlAttrs = getGlobalHtmlAttrs(otherProps)

  const label = intl.formatMessage(messages.readonlyLabel, { value })

  const items = array.range(1, 5).map((itemValue) => {
    // width of the current item from 0 to 1
    const part = Math.max(0, Math.min(1, value - itemValue + 1))

    return (
      <div key={itemValue} className={s.star}>
        {
          part > 0 && (
            <div className={cx(s.fill, fillStyle)} style={{ width: `${100 * part}%` }} />
          )
        }
      </div>
    )
  })

  return (
    <div
      {...htmlAttrs}
      className={cx(s.rating, className, s[`size-${size}`])}
      aria-label={label}
      role="img"
    >
      {items}
    </div>
  )
}

const ControlledRating: React.FunctionComponent<RatingProps> = (props) => {
  const { className, hintClassName, value, size, withHint, onChange, onKeyDown, hintPosition, fillStyle = 'bg-gold-50', ...otherProps } = props

  const [ hoveredValue, setHoveredValue ] = useState(null)

  // width of the current item from 0 to 1
  const currentValue = hoveredValue || value

  const resetHoveredValue = useCallback(() => {
    setHoveredValue(null)
  }, [])

  const intl = useIntl()
  const htmlAttrs = getGlobalHtmlAttrs(otherProps)
  const ratingContainerRef = useRatingKeyboard({ activeRating: currentValue })

  const label = intl.formatMessage(messages.selectableLabel)

  const items = array.range(1, 5).map((itemValue) => {
    const part = Math.max(0, Math.min(1, currentValue - itemValue + 1))

    const label = intl.formatMessage(messages.item, { value: itemValue })

    const handleClick = () => {
      onChange(itemValue)
    }

    const handleMouseEnter = () => {
      setHoveredValue(itemValue)
    }

    const handleKeyDown = (event: React.KeyboardEvent) => {
      onKeyDown(event)
      onChange(itemValue)
    }

    return (
      <button
        key={itemValue}
        className={s.button}
        type="button"
        aria-label={label}
        onClick={handleClick}
        onMouseEnter={handleMouseEnter}
        onFocus={handleMouseEnter}
        onKeyDown={handleKeyDown}
      >
        <div className={s.star}>
          <div className={cx(s.fill, fillStyle)} style={{ width: `${100 * part}%` }} />
        </div>
      </button>
    )
  })

  const labelId = useUniqueId('rating')
  const withTopHint = withHint && hintPosition === 'top'
  const withBottomHint = withHint && !withTopHint

  return (
    <>
      {
        withTopHint && (
          <Text
            className={cx('mb-16', hintClassName)}
            message={messages.valueHint[currentValue] || messages.emptyHint}
            color="black"
            style="p4"
          />
        )
      }
      <div
        {...htmlAttrs}
        ref={ratingContainerRef}
        className={cx(s.rating, className, s[`size-${size}`])}
        aria-label={label}
        aria-describedby={labelId}
        data-testid="selectableRating"
        onMouseLeave={resetHoveredValue}
      >
        {items}
      </div>
      {
        withBottomHint && (
          <Text
            className={cx('mt-4', hintClassName)}
            message={messages.valueHint[currentValue] || messages.emptyHint}
            color={currentValue ? 'gold-50' : 'black'}
            style="p4"
          />
        )
      }
    </>
  )
}

// ATTN we use two components to reuse styles and minimize dom elements for performance
const Rating: React.FunctionComponent<RatingProps> = (props) => {
  if (typeof props.onChange === 'function') {
    return (
      <ControlledRating {...props} />
    )
  }

  return (
    <ReadonlyRating {...props} />
  )
}


export default React.memo(Rating)
