import React, { forwardRef } from 'react'
import { twcx } from 'helpers'

import { RichText, Text, type TextStyle } from 'components/dataDisplay'

import s from './Label.module.css'


export const bgColorToClassName = {
  'black': 'bg-black',
  'brown-red': 'bg-brown-red',
  'gold-30': 'bg-gold-30',
  'gold-70': 'bg-gold-70',
  'faded-pink': 'bg-faded-pink',
  'vinous': 'bg-vinous',
  'white': 'bg-white',
  'gray-30': 'bg-gray-30',
} as const

export const colorToClassName = {
  'white': 'text-white',
  'black': 'text-black',
  'gold-30': 'text-gold-30',
  'gold-70': 'text-gold-70',
} as const

export const indentToClassName = {
  16: 'pl-16',
  32: 'pl-32',
  40: 'pl-40',
  56: 'pl-56',
}

export type BgColor = keyof typeof bgColorToClassName
export type TextColor = keyof typeof colorToClassName
export type Direction = 'left' | 'right'
export type Indent = keyof typeof indentToClassName

export type BaseLabelProps = {
  children?: React.ReactNode
  className?: string
  absolute?: boolean
  title: string | Intl.Message
  bgColor: BgColor
  textColor: TextColor
  direction?: Direction
  indent?: Indent
  withTail?: boolean
  style?: Extract<TextStyle, 'eye1' | 'eye2' | 'eye3' | 't5' | 'h5'>
  html?: boolean
  'data-testid'?: string
}

type LabelPropsWithRichText = Omit<BaseLabelProps, 'title' | 'textColor'> & {
  richText: string
}

type LabelProps = BaseLabelProps | LabelPropsWithRichText

const Label = forwardRef<HTMLDivElement, LabelProps>((props, ref) => {
  const {
    children,
    className,
    absolute = false,
    bgColor,
    indent,
    direction = 'left',
    withTail,
    style = 'eye3',
    html,
    'data-testid': dataTestId,
  } = props

  const rootClassName = twcx(
    'inline-block whitespace-nowrap px-[14rem] py-[5rem]',
    absolute ? 'absolute' : 'relative',
    bgColorToClassName[bgColor],
    'textColor' in props ? colorToClassName[props.textColor] : undefined,
    direction === 'left' && indentToClassName[indent],
    className,
    {
      [s[`direction-${direction}`]]: direction,
      [s.withTail]: withTail,
    })

  const isRichText = 'richText' in props

  return (
    <div className={rootClassName} data-testid={dataTestId}>
      {
        isRichText
          ? <RichText ref={ref} message={props.richText} />
          : <Text ref={ref} message={props.title} style={style} color={props.textColor} html={html}>{children}</Text>
      }
    </div>
  )
})

Label.displayName = 'Label'

export default Label

