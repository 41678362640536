import React, { useEffect, useState } from 'react'
import cx from 'classnames'


export type FadeCarouselProps = {
  children?: React.ReactNode
  autoplaySpeed?: number
  className?: string
  slideClassName?: string
}

const AUTOPLAY_SPEED = 2.5 * 1000

const FadeCarousel: React.FunctionComponent<FadeCarouselProps> = (props) => {
  const { autoplaySpeed = AUTOPLAY_SPEED, children, className, slideClassName } = props

  const [ activeIndex, setActiveIndex ] = useState<number>(0)

  const slidesCount = React.Children.count(children)

  useEffect(() => {
    const autoplayInterval = setInterval(() => {
      setActiveIndex((index) => {
        const nextIndex = index + 1

        if (nextIndex === slidesCount) {
          return 0
        }

        return nextIndex
      })
    }, autoplaySpeed)

    return () => {
      clearInterval(autoplayInterval)
    }
  }, [ autoplaySpeed, slidesCount ])

  return (
    <div className={cx('relative', className)}>
      {
        React.Children.map(children, (child, index) => {
          const active = index === activeIndex
          const slideClasses = cx(
            'absolute w-full min-w-[50rem] select-none place-content-center opacity-0 transition-opacity duration-700 ease-in-out',
            slideClassName,
            active && 'opacity-100'
          )

          return (
            <div className={slideClasses}>
              {child}
            </div>
          )
        })
      }
    </div>
  )
}

export default FadeCarousel
