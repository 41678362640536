import React, { useRef, useCallback, useState, forwardRef, startTransition } from 'react'
import { Helmet } from 'react-helmet-async'
import logger from 'logger'

import { getImageProps } from './util/getImageProps'


const handleLoading = (
  img: HTMLImageElement,
  withLoader: ImageElementProps['withLoader'],
  onLoadRef: ImageElementProps['onLoadRef'],
  setBlurComplete: ImageElementProps['setBlurComplete']
) => {
  const src = img?.src

  if (!img || img['data-loaded-src'] === src) {
    return
  }

  img['data-loaded-src'] = src

  const promise = 'decode' in img ? img.decode() : Promise.resolve()
  void promise.catch(() => {}).then(() => {
    if (!img.parentElement || !img.isConnected) {
      // Exit early in case of race condition:
      // - onload() is called
      // - decode() is called but incomplete
      // - unmount is called
      // - decode() completes
      return
    }
    if (withLoader) {
      startTransition(() => {
        setBlurComplete(true)
      })
    }
    if (onLoadRef.current) {
      // Since we don't have the SyntheticEvent here,
      // we must create one with the same shape.
      // See https://reactjs.org/docs/events.html
      const event = new Event('load')
      Object.defineProperty(event, 'target', { writable: false, value: img })
      let prevented = false
      let stopped = false
      onLoadRef.current({
        ...event,
        nativeEvent: event,
        currentTarget: img,
        target: img,
        isDefaultPrevented: () => prevented,
        isPropagationStopped: () => stopped,
        persist: () => {
        },
        preventDefault: () => {
          prevented = true
          event.preventDefault()
        },
        stopPropagation: () => {
          stopped = true
          event.stopPropagation()
        },
      })
    }

    if (__DEV__) {
      if (img.hasAttribute('data-fill') && (!img.getAttribute('sizes') || img.getAttribute('sizes') === '100vw')) {
        let widthViewportRatio = img.getBoundingClientRect().width / window.innerWidth
        if (widthViewportRatio < 0.6) {
          // eslint-disable-next-line stylistic/max-len
          logger.warn(`Image with src "` + src + `" has "fill" but is missing "sizes" prop. Please add it to improve page performance. Possible value: ${img.getBoundingClientRect().width}.`)
        }
      }
    }
  })
}

type ImageElementProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  fetchPriority: string
  withLoader: boolean
  setShowAltText: (value: boolean) => void
  setBlurComplete: (value: boolean) => void
  onLoadRef: React.RefObject<React.ReactEventHandler<HTMLImageElement> | undefined>
}

const ImageElement = forwardRef<HTMLImageElement, ImageElementProps>((props, forwardedRef) => {
  const {
    loading,
    decoding,
    width,
    height,
    // ATTN we apply empty alt by default to avoid possible accessibility issues
    alt = '',
    style,
    src,
    srcSet,
    sizes,
    fetchPriority,
    withLoader,
    setShowAltText,
    setBlurComplete,
    onLoadRef,
    onError,
    ...rest
  } = props

  return (
    <img
      {...rest}
      // @ts-expect-error for react >=18.3.0 need to use fetchPriority instead
      fetchpriority={fetchPriority}
      // It's intended to keep `loading` before `src` because React updates
      // props in order which causes Safari/Firefox to not lazy load properly.
      // See https://github.com/facebook/react/issues/25883
      loading={loading}
      decoding={decoding}
      width={width}
      height={height}
      alt={alt}
      style={style}
      draggable={false}
      sizes={sizes}
      // It's intended to keep `src` the last attribute because React updates
      // attributes in order. If we keep `src` the first one, Safari will
      // immediately start to fetch `src`, before `sizes` and `srcSet` are even
      // updated by React. That causes multiple unnecessary requests if `srcSet`
      // and `sizes` are defined.
      // This bug cannot be reproduced in Chrome or Firefox.
      srcSet={srcSet}
      src={src}
      ref={useCallback((img: HTMLImageElement) => {
        if (forwardedRef) {
          if (typeof forwardedRef === 'function') {
            forwardedRef(img)
          }
          else if (typeof forwardedRef === 'object') {
            forwardedRef.current = img
          }
        }
        if (!img) {
          return
        }
        if (onError) {
          // If the image has an error before react hydrates, then the error is lost.
          // The workaround is to wait until the image is mounted which is after hydration,
          // then we set the src again to trigger the error handler (if there was an error).

          img.src = img.src
        }

        if (__DEV__) {
          if (!src) {
            console.error('Image is missing src property', img)
          }
          if (img.getAttribute('alt') === undefined) {
            console.error('Image is missing alt property', alt)
          }
        }

        if (img.complete) {
          handleLoading(img, withLoader, onLoadRef, setBlurComplete)
        }
      }, [ alt, forwardedRef, onError, onLoadRef, setBlurComplete, src, withLoader ])}
      onLoad={(event) => {
        const img = event.currentTarget
        handleLoading(img, withLoader, onLoadRef, setBlurComplete)
      }}
      onError={(event) => {
        // if the real image fails to load, this will ensure "alt" is visible
        startTransition(() => {
          setShowAltText(true)
          if (withLoader) {
            // If the real image fails to load, this will still remove the placeholder.
            setBlurComplete(true)
          }
        })
        if (typeof onError === 'function') {
          onError(event)
        }
      }}
    />
  )
})

const NativeImage = forwardRef<HTMLImageElement, Image.Props>((props, ref) => {
  const [ blurComplete, setBlurComplete ] = useState(false)
  const [ showAltText, setShowAltText ] = useState(false)

  const onLoadRef = useRef(props.onLoad)
  onLoadRef.current = props.onLoad

  const imgProps = getImageProps(props, {
    blurComplete,
    showAltText,
  })

  return (
    <>
      <ImageElement
        {...imgProps}
        ref={ref}
        setBlurComplete={setBlurComplete}
        setShowAltText={setShowAltText}
        onLoadRef={onLoadRef}
      />
      {
        Boolean(imgProps.srcSet && imgProps.fetchPriority) && (
          <Helmet>
            <link
              rel="preload"
              as="image"
              imageSrcSet={imgProps.srcSet}
              imageSizes={imgProps.sizes}
              // @ts-expect-error
              fetchpriority="high"
            />
          </Helmet>
        )
      }
    </>
  )
})


export default NativeImage
